import React, { useState } from "react";
import "./People.css"; // Import CSS for styling the page

// Import images
import XinjieChenImage from "../assets/team-autograph/Xinjie-Chen-New-Large.png";
import CharlotteHonImage from "../assets/team-autograph/Charlott-Hon-large.jpg";
import ZhenyiZhouImage from "../assets/team-autograph/Zhenyi-Zhou-medium.jpg";
import MaisieGlofcheskiImage from "../assets/team-autograph/Maisie-Glofcheski-large.jpg";
import YixinWangImage from "../assets/team-autograph/Yixin-Wang-large.jpg"; // Adjust path based on your project
import KamanChilnImage from "../assets/team-autograph/Kaman-Chin.JPG";
import MaisyWongImage from "../assets/team-autograph/Maisy-Wong.jpeg";
import CliveLinImage from "../assets/team-autograph/Clive-Lin.jpg";

const People = () => {
  // Team members data
  const members = [
    {
      name: "Maisie Glofcheski",
      summary: `I am originally from Ottawa, Canada. I received my Bachelor of Arts (B.A.) in
Psychology at Trent University, Peterborough, Canada, and my Master of Education
(M.Ed.) in Societies, Cultures, and Language at the University of Ottawa, Ottawa,
Canada. In 2019, I moved to Asia to start a new journey as a tertiary lecturer at the
Hong Kong University of Science (HKUST), involved in course leading and research
work in language education. Presently, I have joined CUHK as a doctoral student,
excited to merge my interests in both positive psychology and language education.
When I am not working, you can often find me in the yoga studio. I have been practicing
yoga for 10+ years, and I am a certified yoga teacher (RYT 200hrs) with a specialization
in aerial yoga (CET 50hrs). Yoga is my secret to maintaining a healthy balance between
my busy personal and professional life. I am always happy to share my practice with
others!`,
      bio: [
        `I am originally from Ottawa, Canada. I received my Bachelor of Arts (B.A.) in
Psychology at Trent University, Peterborough, Canada, and my Master of Education
(M.Ed.) in Societies, Cultures, and Language at the University of Ottawa, Ottawa,
Canada. In 2019, I moved to Asia to start a new journey as a tertiary lecturer at the
Hong Kong University of Science (HKUST), involved in course leading and research
work in language education. Presently, I have joined CUHK as a doctoral student,
excited to merge my interests in both positive psychology and language education.
When I am not working, you can often find me in the yoga studio. I have been practicing
yoga for 10+ years, and I am a certified yoga teacher (RYT 200hrs) with a specialization
in aerial yoga (CET 50hrs). Yoga is my secret to maintaining a healthy balance between
my busy personal and professional life. I am always happy to share my practice with
others!`,
      ],
      image: MaisieGlofcheskiImage,
    },
    {
      name: "Charlotte Hon",
      summary:
        "I majored in Psychology and Linguistics (M.A. First Class Honours) at The University of Edinburgh, Scotland. In 2022, I embarked on my teaching journey at a local primary school in Hong Kong. I have a growing passion for tennis and yoga. Love a good social!",
      bio: `I majored in Psychology and Linguistics (M.A. First Class Honours) at The University of Edinburgh, Scotland. In 2022, I embarked on my teaching journey at a local primary school in Hong Kong. I have a growing passion for tennis and yoga. Love a good social!`,
      image: CharlotteHonImage,
    },
    {
      name: "Yixin Wang",
      summary:
        "I am currently pursuing a Bachelor of Arts degree at Tsinghua University, specializing in second language acquisition and foreign literature. My research interests focus on the effects of various teaching methodologies on learner motivation and engagement. Passionate about enhancing educational effectiveness and equality, I am particularly dedicated to teaching in underdeveloped areas and advocating for curriculum innovation and hope to pursue a master’s degree in education to further my understanding in the field.",
      bio: [
        "I am currently pursuing a Bachelor of Arts degree at Tsinghua University, specializing in second language acquisition and foreign literature. My research interests focus on the effects of various teaching methodologies on learner motivation and engagement. Passionate about enhancing educational effectiveness and equality, I am particularly dedicated to teaching in underdeveloped areas and advocating for curriculum innovation and hope to pursue a master’s degree in education to further my understanding in the field.",
      ],
      image: YixinWangImage,
    },
    {
      name: "Zhenyi Zhou",
      summary: `I received my bachelor’s degree of Arts in Education from Beijing Normal University
in 2022. I’m currently a master student in the Department of Psychology in CUHK.
My research includes positive psychology (e.g., mindfulness-based interventions and
resilience), emotional and cognitive development across life span with quantitative
methods and meta-analysis. Outside the lab, I make it a habit to stay active through
badminton and unwind with friends over mahjong. Additionally, my passion for
photography drives me to document and appreciate the beauty of everyday life.`,
      bio: [
        `I received my bachelor’s degree of Arts in Education from Beijing Normal University
in 2022. I’m currently a master student in the Department of Psychology in CUHK.
My research includes positive psychology (e.g., mindfulness-based interventions and
resilience), emotional and cognitive development across life span with quantitative
methods and meta-analysis. Outside the lab, I make it a habit to stay active through
badminton and unwind with friends over mahjong. Additionally, my passion for
photography drives me to document and appreciate the beauty of everyday life.`,
      ],
      image: ZhenyiZhouImage,
    },
    {
      name: "Kaman Chin",
      summary: `I am Chin Ka Man (Kaman) from Hong Kong, an undergraduate student at the Chinese University of Hong Kong (CUHK), majoring in psychology. I also work as a subject-based tutor for Form 1, Form 2, and Form 5 students, including at my alma mater, St. Stephen’s Girls’ College. In this role, I help students enhance their problem-solving skills and conceptual understanding, resulting in measurable improvements in their academic performance. Outside of school, I enjoy listening to Cantopop and watching Netflix. As a talkative and outgoing person, I value spending quality time with friends. My experiences as a school prefect and chairperson of the Students' Welfare Board in the students' association have strengthened my communication and collaboration skills. Additionally, my participation in essay and case competitions during secondary school developed my research abilities, which I believe will enable me to contribute effectively as a student helper in the lab. I am particularly interested in the research of language and educational psychology, and I look forward to gaining deeper insights into these areas through my work in the lab.`,
      bio: `I am Chin Ka Man (Kaman) from Hong Kong, an undergraduate student at the Chinese University of Hong Kong (CUHK), majoring in psychology. I also work as a subject-based tutor for Form 1, Form 2, and Form 5 students, including at my alma mater, St. Stephen’s Girls’ College. In this role, I help students enhance their problem-solving skills and conceptual understanding, resulting in measurable improvements in their academic performance. Outside of school, I enjoy listening to Cantopop and watching Netflix. As a talkative and outgoing person, I value spending quality time with friends. My experiences as a school prefect and chairperson of the Students' Welfare Board in the students' association have strengthened my communication and collaboration skills. Additionally, my participation in essay and case competitions during secondary school developed my research abilities, which I believe will enable me to contribute effectively as a student helper in the lab. I am particularly interested in the research of language and educational psychology, and I look forward to gaining deeper insights into these areas through my work in the lab.`,
      image: KamanChilnImage,
    },
    {
      name: "Maisy Wong",
      summary: `I am Mei Ching (Maisy) Wong, a local student from Hong Kong, currently pursuing a Bachelor of Social Science in Psychology at CUHK. I have gained academic knowledge in psychology through courses such as Memory & Cognition and Social Psychology, as well as practical experience working as an English tutor. I am particularly interested in how mindsets affect learning efficiency and how positive psychology can enhance learning. In my spare time, I enjoy reading and singing.`,
      bio: `I am Mei Ching (Maisy) Wong, a local student from Hong Kong, currently pursuing a Bachelor of Social Science in Psychology at CUHK. I have gained academic knowledge in psychology through courses such as Memory & Cognition and Social Psychology, as well as practical experience working as an English tutor. I am particularly interested in how mindsets affect learning efficiency and how positive psychology can enhance learning. In my spare time, I enjoy reading and singing.`,
      image: MaisyWongImage,
    },
    {
      name: "Clive Lin",
      summary: ` Hi, this is Clive (Lin, Fung Tat)! I obtained a bachelor's degree in History at the Chinese University of Hong Kong in 2024 and am currently studying for the Postgraduate Diploma of Secondary Education in History at CUHK. My particular fields of research are Hong Kong and modern China, especially related to political, social, and cultural. I like investigating and discovering hidden pictures and the significance of a phenomenon or constructed image. Therefore, I have worked on some research on the cultural heritage and tourism of Hong Kong. In addition, I am also keen to know the mind of humans with the outside environment. I minored in anthropology, learned to think more deeply, and did some work related to culture and identity. Also, I was invited to share one of my papers on the relationship between busking and public space in Hong Kong at a forum at National Taiwan University in 2024. During my undergraduate studies, I have been fortunate enough to receive several awards. Includes, but is not limited to: the 2023 Outstanding Student Award, Reaching Out Award 2022/2023, Talent Development Scholarship (2021), and different scholarships with wonderful volunteering experiences, such as being the Hong Kong Young Ambassador since 2021. For now, as a postgraduate student of education, I would like to explore more education-related topics with the mix of my previous research experiences, broadening my research knowledge in the field and learning more from seniors.`,
      bio: `Hi, this is Clive (Lin, Fung Tat)! I obtained a bachelor's degree in History at the Chinese University of Hong Kong in 2024 and am currently studying for the Postgraduate Diploma of Secondary Education in History at CUHK. My particular fields of research are Hong Kong and modern China, especially related to political, social, and cultural. I like investigating and discovering hidden pictures and the significance of a phenomenon or constructed image. Therefore, I have worked on some research on the cultural heritage and tourism of Hong Kong. In addition, I am also keen to know the mind of humans with the outside environment. I minored in anthropology, learned to think more deeply, and did some work related to culture and identity. Also, I was invited to share one of my papers on the relationship between busking and public space in Hong Kong at a forum at National Taiwan University in 2024. During my undergraduate studies, I have been fortunate enough to receive several awards. Includes, but is not limited to: the 2023 Outstanding Student Award, Reaching Out Award 2022/2023, Talent Development Scholarship (2021), and different scholarships with wonderful volunteering experiences, such as being the Hong Kong Young Ambassador since 2021. For now, as a postgraduate student of education, I would like to explore more education-related topics with the mix of my previous research experiences, broadening my research knowledge in the field and learning more from seniors.`,
      image: CliveLinImage,
    },
  ];

  const director = [
    {
      name: "Dr. CHEN Xinjie, Nathalie",
      summary:
        "Dr. Xinjie (Nathalie) CHEN is an assistant professor in the Faculty of Education at the Chinese University of Hong Kong (CUHK), where she serves as the director of the Language Education and Positive Psychology (LEaPP) Lab. Prior to her appointment at CUHK, she was a senior research associate and lecturer at Stanford University, where she also served as the research director for the California World Language Project. Her research has taken different forms and resulted in a series of publications in leading journals in the field of psychology, education, and language, such as Educational Psychology Review, Journal of Happiness Studies, Journal of Positive Psychology, International Journal of Educational Research, Foreign Language Annals, Journal of Bilingual Education and Bilingualism, Journal of Multilingual and Multicultural Development. Currently, Dr. Chen sits on the editorial board of academic journals Educational Psychology, and School Psychology International (senior associate editor). Dr. Chen has been invited to serve as a guest editor and as an Ad hoc reviewer for a significant number of international academic peer-reviewed journals in the past few years.",
      bio: [
        "Dr. Xinjie (Nathalie) CHEN is an assistant professor in the Faculty of Education at the Chinese University of Hong Kong (CUHK), where she serves as the director of the Language Education and Positive Psychology (LEaPP) Lab. Prior to her appointment at CUHK, she was a senior research associate and lecturer at Stanford University, where she also served as the research director for the California World Language Project. Her research has taken different forms and resulted in a series of publications in leading journals in the field of psychology, education, and language, such as Educational Psychology Review, Journal of Happiness Studies, Journal of Positive Psychology, International Journal of Educational Research, Foreign Language Annals, Journal of Bilingual Education and Bilingualism, Journal of Multilingual and Multicultural Development. Currently, Dr. Chen sits on the editorial board of academic journals Educational Psychology, and School Psychology International (senior associate editor). Dr. Chen has been invited to serve as a guest editor and as an Ad hoc reviewer for a significant number of international academic peer-reviewed journals in the past few years.",
      ],
      contact: "https://www.fed.cuhk.edu.hk/eps/CHENN.html",
      image: XinjieChenImage,
    },
  ];

  const [expandedMemberIndex, setExpandedMemberIndex] = useState(null);

  const toggleDetails = (index) => {
    setExpandedMemberIndex(expandedMemberIndex === index ? null : index);
  };

  return (
    <div className="people-container">
      <h1 className="people-title">Our Team</h1>

      {/* Director Section */}
      <h2 className="team-section-title">Director</h2>
      <div className="team-grid director-card">
        {director.map((member, index) => (
          <div key={index} className="team-card">
            <div className="team-card-image-container">
              <img
                src={member.image}
                alt={member.name}
                className="team-card-image"
              />
            </div>
            <div className="team-card-body">
              <h3 className="team-card-name">{member.name}</h3>
              <p className="team-card-summary">
                {expandedMemberIndex === index
                  ? member.bio.join(" ")
                  : member.summary}
              </p>
              {/* <span
                className="more-details"
                onClick={() => toggleDetails(index)}
              >
                {expandedMemberIndex === index ? "Show less" : "More details"}
              </span> */}
            </div>
          </div>
        ))}
      </div>

      {/* Members Section */}
      <h2 className="team-section-title">Members</h2>
      <div className="team-grid">
        {members.map((member, index) => (
          <div key={index} className="team-card">
            <div className="team-card-image-container">
              <img
                src={member.image}
                alt={member.name}
                className="team-card-image"
              />
            </div>
            <div className="team-card-body">
              <h3 className="team-card-name">{member.name}</h3>
              <p className="team-card-summary">
                {expandedMemberIndex === index + director.length
                  ? member.bio
                  : member.summary}
              </p>
              {/* <span
                className="more-details"
                onClick={() => toggleDetails(index + director.length)}
              >
                {expandedMemberIndex === index + director.length
                  ? "Show less"
                  : "More details"}
              </span> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default People;
